// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { BASE_URL } from '../../../../../utility/services'

export const getCms = createAsyncThunk('appCms/getCms', async (params = {}, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}cms/get`)
    if (response.data?.status) {
      return {
        data: response.data.data
      }
    }
  } catch (error) {
    console.log(error)
    // toast.error(error || "Something went wrong")
    rejectWithValue(error)
  }

})

export const updateCms = createAsyncThunk('appCms/updateCms', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.patch(`${BASE_URL}cms/update`, payload)
    if (response.data?.status) {
      toast.success("Cms updated successfully")
      // dispatch(getData())
      return response.data?.data
    } else {
      toast.error(response.data?.message)
    }
  } catch (error) {
    console.log(error?.message)
    toast.error(error?.message || "Something went wrong")
    rejectWithValue(error)
  }
})

export const appCmsSlice = createSlice({
  name: 'appCms',
  initialState: {
    loading: false,
    data: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCms.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload?.data
      })
      .addCase(getCms.pending, (state) => {
        state.loading = true
      })
  }
})

export default appCmsSlice.reducer

