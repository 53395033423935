import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../utility/services'

export const getAllStore = createAsyncThunk('buisness/getData', async () => {
    const response = await axios.get(`${BASE_URL}business/getAll`)
    return {
      data: response.data
    }
  })
  export const addStore = createAsyncThunk('appStore/addStore', async (store) => {
    await axios.post(`${BASE_URL}business/create`, store)
    return store
  })
  export const updateStore = createAsyncThunk('appStore/updateStore', async (store) => {
    await axios.put(`${BASE_URL}business/update/${store.id}`, store.data)
    return store
  })
  export const getStoreById = createAsyncThunk('appStore/getBusinessById', async (id) => {
    const response = await axios.get(`${BASE_URL}business/${id}`)
    return {data: response.data
    }
  
  })

  export const myStoreSlice = createSlice({
    name: 'appStore',
    initialState: {
      data: [],
      allData: [],
      store: {}
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getAllStore.fulfilled, (state, action) => {
          state.data = action.payload.data
        })
        .addCase(getStoreById.fulfilled, (state, action) => {
          state.store = action.payload.data
        })
    }
})

export default myStoreSlice.reducer