// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import category from '@src/views/main/category/store'
import cms from '@src/views/main/cms/cmsDashboard/store'
import product from '@src/views/main/products/store'
import order from '@src/views/main/order/store'
import store from '@src/views/main/myStore/store'

const rootReducer = {
  // main
  category,
  cms,
  product,
  order,
  store,
  auth,
  layout,
  todo,
  chat,
  email,
  users,
  navbar,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions
}

export default rootReducer
