// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { BASE_URL } from '../../../../utility/services'

export const getAllData = createAsyncThunk(`appProduct/getAllproduct`, async () => {
  try {
    const response = await axios.get(`${BASE_URL}product/getAll`)
    return response.data
  } catch (error) {
    console.log("getAllData error", error)
  }
})

export const getData = createAsyncThunk('appProduct/getData', async (params = {}) => {
  const response = await axios.get(`${BASE_URL}product/getAll`)
  console.log(response)
  return {
    params,
    data: response.data,
    totalPages: response.data.length
  }
})

export const getUser = createAsyncThunk('appProduct/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})


export const deleteUser = createAsyncThunk('appProduct/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  // await dispatch(getAllData())
  return id
})

export const getProductById = createAsyncThunk('appProduct/getProductById', async (id) => {
  const response = await axios.get(`${BASE_URL}product/${id}`)
  return response.data

})

export const addProduct = createAsyncThunk('appProduct/addproduct', async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}product/create`, payload)
    if (response.data.status) {
      toast.success("Product Create Successfully")
      return {
        data: response.data
      }
    } else {
      rejectWithValue(response.message)
    }
  } catch (err) {
    toast.error(err.message)
  }
})

export const updateProduct = createAsyncThunk('appProduct/updateProduct', async (payload, { rejectWithValue }) => {
  const response = await axios.patch(`${BASE_URL}product/update/${payload.id}`, payload.data)
  if (response.data.status) {
    toast.success("Product Update Successfully")
    return {
      data: response.data
    }
  } else {
    toast.error(response.message)
    rejectWithValue(response.message)
  }
})

export const appProductSlice = createSlice({
  name: 'appProduct',
  initialState: {
    data: [],
    total: 1,
    loading: false,
    params: {},
    allData: [],
    selectedUser: null,
    product: {}
  },
  reducers: {
    clearProduct: (state) => {
      state.product = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload?.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.product = action.payload
        state.loading = false
      })
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
      .addCase(getProductById.pending, (state) => {
        state.loading = true
      })
    // .addCase(getUser.fulfilled, (state, action) => {
    //   state.selectedUser = action.payload
    // })
  }
})
export const { clearProduct } = appProductSlice.actions
export default appProductSlice.reducer
