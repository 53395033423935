import React from 'react'
import { Spinner } from 'reactstrap'

const PageSpinner = () => {

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "60vh", width: "100%"}}>
            <Spinner type='grow' color='primary' size="lg" />
        </div>
    )
}
export default PageSpinner