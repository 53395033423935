// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { BASE_URL } from '../../../../utility/services'

export const getAllOrder = createAsyncThunk('appOrder/getAllOrder', async (params = {}) => {
    const response = await axios.get(`${BASE_URL}order/getAll`)
    console.log(response)
    return {
      params,
      data: response.data,
      totalPages: response.data.length
    }
  })

  export const getOrderById = createAsyncThunk('appOrder/getOrderById', async (id) => {
    const response = await axios.get(`${BASE_URL}order/${id}`)
    return response.data
    
  })

  export const updateOrderStatus = createAsyncThunk('appOrder/updateStatus', async (payload) => {
    const response = await axios.patch(`${BASE_URL}order/updateStatus/${payload.id}`, payload)
    return {data: response.data
    }
  })
  export const appOrderSlice = createSlice({
    name: 'appOrder',
    initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      loading: false,
      orderDetail:{},
      detailLoading: false
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getAllOrder.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.totalPages
          state.loading = false
        })
        .addCase(getAllOrder.rejected, (state, action) => {
          state.loading = false
        })
        .addCase(getAllOrder.pending, (state, action) => {
          state.loading = true
        })
        .addCase(getOrderById.fulfilled, (state, action) => {
          state.orderDetail = action.payload
          state.detailLoading = false
        })
        .addCase(getOrderById.rejected, (state, action) => {
          state.detailLoading = false
        })
        .addCase(getOrderById.pending, (state, action) => {
          state.detailLoading = true
        })
       
    }
  })
  
  export default appOrderSlice.reducer