// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axios from 'axios'
import { BASE_URL } from '../utility/services'
import toast from 'react-hot-toast'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}


export const loginAction = createAsyncThunk('appAuth/login', async (payload, { rejectWithValue, dispatch }) => {
  let loginData = {
    ability: [{
      action: "manage",
      subject: "all"
    }],
    accessToken: "",
    avatar: "/static/media/avatar-s-11.1d46cc62.jpg",
    email: "admin@demo.com",
    fullName: "John Doe",
    id: 1,
    refreshToken: "",
    role: "admin",
    username: "johndoe"
  }

  try {
    const response = await axios.post(`${BASE_URL}business/login`, payload)
    if (response.data.status) {
      return {
        data: {
          ...response.data,
          data: {
            ...loginData,
            ...response.data.data
          }
        }
      }
    } else {
      // toast.error(response.message)
      rejectWithValue(response.message)
    }
  } catch (error) {
    console.log({ error })
    toast.error(error.response?.data?.message || "Something wrong!")
    rejectWithValue(error)
  }
})

export const signupAction = createAsyncThunk('appAuth/signup', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${BASE_URL}business/signup`, payload)
    if (response.data?._id) {
      return {
        data: {
          ...response.data,
        }
      }
    } else {
      // toast.error(response.message)
      rejectWithValue(response.message)
    }
  } catch (error) {
    console.log({ error })
    toast.error(error.response?.data?.message || "Something wrong!")
    rejectWithValue(error)
  }
})




export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      console.log({ data: action.payload })
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem("accessToken")
      localStorage.removeItem('refreshToken')
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
