// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { BASE_URL } from '../../../../utility/services'

// export const deleteUser = createAsyncThunk('appCategory/deleteUser', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/users/delete', { id })
//   await dispatch(getData(getState().users.params))
//   // await dispatch(getAllData())
//   return id
// })


export const getAllData = createAsyncThunk(`appCategory/getAllCategory`, async () => {
  console.log("getAllData")
  try {
    const response = await axios.get(`${BASE_URL}category/getAll`)
    return response.data
  } catch (error) {
    console.log("getAllData error", error)
  }
})

export const getData = createAsyncThunk('appCategory/getData', async (params = {}, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${BASE_URL}category/getAll`)
    if (response.data?.status) {
      return {
        params,
        data: response.data.data,
        totalPages: response.data.data.length / 10
      }
    }
  } catch (error) {
    console.log(error)
    // toast.error(error || "Something went wrong")
    rejectWithValue(error)
  }

})

export const addCategory = createAsyncThunk('appCategory/addCategory', async (user, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}category/create`, user)
    if (response.data?.status) {
      toast.success("Category created successfully")
      dispatch(getData())
      return response.data?.data
    } else {
      toast.error(response.data?.message)
    }
  } catch (error) {
    console.log(error?.message)
    toast.error(error?.message || "Something went wrong")
    rejectWithValue(error)
  }
})

export const updateCategory = createAsyncThunk('appCategory/updateCategory', async ({
  id,
  payload
}, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.patch(`${BASE_URL}category/update/${id}`, payload)
    if (response.data?.status) {
      toast.success("Category created successfully")
      dispatch(getData())
      return response.data?.data
    } else {
      toast.error(response.data?.message)
    }
  } catch (error) {
    console.log(error?.message)
    toast.error(error?.message || "Something went wrong")
    rejectWithValue(error)
  }
})

export const deleteCategory = createAsyncThunk('appCategory/deleteCategory', async (id, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.delete(`${BASE_URL}category/delete/${id}`)
    if (response.data?.status) {
      toast.success("Category deleted successfully")
      dispatch(getData())
      return response.data?.data
    } else {
      toast.error(response.data?.message)
    }
  } catch (error) {
    console.log(error?.message)
    toast.error(error?.message || "Something went wrong")
    rejectWithValue(error)
  }
})

export const appCategorySlice = createSlice({
  name: 'appCategory',
  initialState: {
    loading: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getData.pending, (state) => {
        state.loading = true
      })
  }
})

export default appCategorySlice.reducer
