// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
const CategoryList = lazy(() => import('../../views/main/category/list'))
const ProductList = lazy(() => import('../../views/main/products/list'))
const ProductCreate = lazy(() => import('../../views/main/products/create'))
const Dashboard = lazy(() => import('../../views/main/dashboard'))
const OrderList = lazy(() => import('../../views/main/order/list'))
const OrderDetail = lazy(() => import('../../views/main/order/orderDetail/detail'))
const StoreList = lazy(() => import('../../views/main/myStore/list'))
const StoreCreate = lazy(() => import('../../views/main/myStore/create'))
const CMS = lazy(() => import('../../views/main/cms/cmsDashboard'))

const DashboardEcommerce = lazy(() => import('../../views/dashboard/ecommerce'))
const DashboardAnalytics = lazy(() => import('../../views/dashboard/analytics'))
const ContactUsEdit = lazy(() => import('../../views/main/pages/Generic/edit'))


// const Chat = lazy(() => import('../../views/apps/chat'))
// const Todo = lazy(() => import('../../views/apps/todo'))
// const Email = lazy(() => import('../../views/apps/email'))
// const Calendar = lazy(() => import('../../views/apps/calendar'))

// const InvoiceAdd = lazy(() => import('../../views/apps/invoice/add'))
// const InvoiceList = lazy(() => import('../../views/apps/invoice/list'))
// const InvoiceEdit = lazy(() => import('../../views/apps/invoice/edit'))
// const InvoicePrint = lazy(() => import('../../views/apps/invoice/print'))
// const InvoicePreview = lazy(() => import('../../views/apps/invoice/preview'))

// const EcommerceShop = lazy(() => import('../../views/apps/ecommerce/shop'))
// const EcommerceDetail = lazy(() => import('../../views/apps/ecommerce/detail'))
// const EcommerceWishlist = lazy(() => import('../../views/apps/ecommerce/wishlist'))
// const EcommerceCheckout = lazy(() => import('../../views/apps/ecommerce/checkout'))

// const UserList = lazy(() => import('../../views/apps/user/list'))
// const UserView = lazy(() => import('../../views/apps/user/view'))

// const Roles = lazy(() => import('../../views/apps/roles-permissions/roles'))
// const Permissions = lazy(() => import('../../views/apps/roles-permissions/permissions'))
import '@styles/react/libs/editor/editor.scss'
import ComingSoon from '../../views/pages/misc/ComingSoon'

export const ROUTE_NAME = {
  dashboard: '/dashboard',
  dashboard2: '/dashboard2',
  category: '/apps/category/list',
  product: '/apps/product/list',
  productCreate: '/apps/product/create',
  productUpdate: '/apps/product/update',
  order: '/apps/order/list',
  orderDetail: '/apps/order/orderDetail',
  myStore: '/apps/myStore/list',
  storeCreate: '/apps/myStore/create',
  storeUpdate: '/apps/myStore/update',
  cms: '/apps/cms',


  marketing: '/apps/marketing',
  seo: '/apps/seo',
  blogs: '/apps/blogs',

  page: '/apps/page',
  contactUs: '/apps/page/contact-us',
  aboutUs: '/apps/page/about-us',

  comingSoon: '/apps/coming-soon',
}

const MainRoutes = [
  {
    element: <Dashboard />,
    path: ROUTE_NAME.dashboard
  },
  {
    element: <DashboardAnalytics />,
    path: ROUTE_NAME.dashboard2
  },
  {
    element: <CategoryList />,
    path: ROUTE_NAME.category
  },
  {
    element: <ProductList />,
    path: ROUTE_NAME.product
  },
  {
    element: <ProductCreate isEdit = {false}/>,
    path: ROUTE_NAME.productCreate
  },
  {
    element: <ProductCreate isEdit = {true}/>,
    path: `${ROUTE_NAME.productUpdate}/:id`
  },
  {
    element: <OrderList />,
    path: ROUTE_NAME.order
  },
  {
    element: <OrderDetail/>,
    path: `${ROUTE_NAME.orderDetail}/:id`
  },
  {
    element: <OrderDetail/>,
    path: `${ROUTE_NAME.orderDetail}/:id`
  },
  {
    element: <StoreList/>,
    path: ROUTE_NAME.myStore
  },
  {
    element: <StoreCreate isEdit={false}/>,
    path: ROUTE_NAME.storeCreate
  },
  {
    element: <StoreCreate isEdit={true}/>,
    path: `${ROUTE_NAME.storeUpdate}/:id`
  },
  {
    element: <CMS/>,
    path: ROUTE_NAME.cms
  },


  // COMING SOON
  {
    element: <ComingSoon/>,
    path: ROUTE_NAME.seo
  },
  {
    element: <ComingSoon/>,
    path: ROUTE_NAME.blogs
  },
  {
    element: <ComingSoon/>,
    path: ROUTE_NAME.marketing
  },



  {
    element: <ContactUsEdit/>,
    path: ROUTE_NAME.page + "/:slug"
  },
  {
    element: <ContactUsEdit/>,
    path: ROUTE_NAME.page + "/:slug"
  }
]
export default MainRoutes
